<template>
  <!-- Table Container Card -->
  <b-card no-body>

    <div class="m-2">

      <!-- Table Top -->
      <b-row>

        <!-- Per Page -->
        <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
          <label>Entries</label>
          <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions"
            :clearable="false" class="per-page-selector d-inline-block ml-50 mr-1" />
          <b-button variant="primary" @click="openUserModal" v-b-modal.modal>
            + Add
          </b-button>
        </b-col>

        <!-- Search -->
        <b-col cols="12" md="6">
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..." />
          </div>
        </b-col>
      </b-row>

    </div>

    <b-table ref="refUserListTable" :items="fetchUsers" responsive :fields="tableColumns" primary-key="id"
      :sort-by.sync="sortBy" show-empty empty-text="No matching records found" :sort-desc.sync="isSortDirDesc"
      class="position-relative" style="height: 600px;">

      <!-- Column: Issued Date -->
      <template #cell(hospital)="data">
        <span class="text-nowrap">
          {{ data.item.attributes.name }}
        </span>
      </template>


      <!-- Column: Issued Date -->
      <template #cell(department)="data">
        <span class="text-nowrap">
          {{ data.item.attributes.department }}
        </span>
      </template>


      <!-- Column: Issued Date -->
      <template #cell(address)="data">
        <span class="text-nowrap">
          {{ `${data.item.attributes.addressCanton} ${data.item.attributes.addressStreet} ,
                    ${data.item.attributes.addressZIP} ${data.item.attributes.addressCity}`
          }}
        </span>
      </template>


      <!-- Column: Issued Date -->
      <template #cell(contactPerson)="data">
        <span class="text-nowrap">
          {{ data.item.attributes.primaryContact ? `${data.item.attributes.primaryContact.attributes.firstName}
                    ${data.item.attributes.primaryContact.attributes.lastName}` : ""
          }}
        </span>
      </template>

      <!-- Column: Issued Date -->
      <template #cell(onboardSince)="data">
        <span class="text-nowrap">
          {{ formatDate(data.item.attributes.createdAt) }}
        </span>
      </template>


      <!-- Column: Issued Date -->
      <template #cell(rate)="data">
        <span class="text-nowrap">
          {{ `CHF ${data.item.attributes.rate}/hr` }}
        </span>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">

        <div class="text-nowrap">
          <!-- Dropdown -->
          <b-dropdown variant="link" toggle-class="p-0" no-caret :right="$store.state.appConfig.isRTL">

            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
            </template>
            <b-dropdown-item @click="editClient(data)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>
            <b-dropdown-item>
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>

    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">

          <b-pagination v-model="currentPage" :total-rows="totalUsers" :per-page="perPage" first-number last-number
            class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
    <div>
      <!-- select 2 demo -->
      <b-modal id="modal" ref="modal" title="Add/Edit Client" hide-footer>
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <b-form @submit.prevent="handleSubmit(onSubmit)">
            <b-row>
              <b-col md="6">
                <validation-provider #default="validationContext" name="Hospital" rules="required">
                  <b-form-group label="Hospital" label-for="hospital">
                    <b-form-input id="hospital" :state="getValidationState(validationContext)" v-model="client.name" />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <validation-provider #default="validationContext" name="Department" rules="required">
                  <b-form-group label="Department" label-for="department">
                    <b-form-input id="department" :state="getValidationState(validationContext)"
                      v-model="client.department" />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <h4>Address and billing data</h4>
            <b-row>
              <b-col md="6">
                <validation-provider #default="validationContext" name="Street" rules="required">
                  <b-form-group label="Street" label-for="street">
                    <b-form-input id="street" :state="getValidationState(validationContext)"
                      v-model="client.addressStreet" />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <validation-provider #default="validationContext" name="PLZ" rules="required">
                  <b-form-group label="PLZ" label-for="plz">
                    <b-form-input id="plz" :state="getValidationState(validationContext)" v-model="client.addressZIP" />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <validation-provider #default="validationContext" name="City" rules="required">
                  <b-form-group label="City" label-for="city">
                    <b-form-input id="city" :state="getValidationState(validationContext)" v-model="client.addressCity" />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <validation-provider #default="validationContext" name="Canton" rules="required">
                  <b-form-group label="Canton" label-for="canton">
                    <b-form-input id="canton" :state="getValidationState(validationContext)"
                      v-model="client.addressCanton" />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <b-form-group label="Rate" label-for="rate">
                  <b-input-group prepend="$">
                    <b-form-input id="rate" v-model="client.rate" type="number" />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Vat No" label-for="vat_no">
                  <b-form-input id="vat_no" v-model="client.vatNumber" />
                </b-form-group>
              </b-col>

              <b-col md="6" class="pt-1 pb-2">
                <b-form-checkbox v-model="client.showAssistantName" switch inline>Show assistant name</b-form-checkbox>
              </b-col>
            </b-row>
            <h4>Contact Person</h4>
            <b-row>
              <b-col md="6">
                <b-form-group label="Primary Contact" label-for="vue-select">
                  <b-form-select id="vue-select" v-model="client.primaryContact" :options="users" />
                </b-form-group>
              </b-col>
              <b-col md="6" class="pt-2">
                <b-form-checkbox v-model="showPrimaryUserForm" switch inline>Add new user</b-form-checkbox>
              </b-col>
            </b-row>
            <div v-if="showPrimaryUserForm">
              <b-row>
                <b-col md="6">
                  <b-form-group label="First Name:" label-for="first-name">
                    <b-form-input id="first-name" v-model="contactPrimaryUser.firstName" autofocus trim />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Last Name:" label-for="last-name">
                    <b-form-input id="last-name" v-model="contactPrimaryUser.lastName" autofocus trim />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="6">
                  <b-form-group label="E-mail:" label-for="e-mail">
                    <b-form-input id="e-mail" v-model="contactPrimaryUser.email" autofocus trim />

                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Phone:" label-for="phone">
                    <b-form-input id="phone" v-model="contactPrimaryUser.phone" autofocus trim />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row class="text-right">
                <b-col md="12">
                  <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" :disabled="!contactPrimaryUser.firstName || !contactPrimaryUser.lastName || !contactPrimaryUser.email ||
                    !contactPrimaryUser.phone" @click="addPrimaryContact">
                    Save
                  </b-button>
                </b-col>
              </b-row>
            </div>


            <b-row>
              <b-col md="6">
                <b-form-group label="Deputy Contact" label-for="vue-select">
                  <b-form-select id="vue-select" v-model="client.deputyContact" :options="users" />
                </b-form-group>
              </b-col>
              <b-col md="6" class="pt-2">
                <b-form-checkbox v-model="showDeputyUserForm" switch inline>Add new user</b-form-checkbox>
              </b-col>
            </b-row>
            <div v-if="showDeputyUserForm">
              <b-row>
                <b-col md="6">
                  <b-form-group label="First Name:" label-for="first-name">
                    <b-form-input id="first-name" v-model="contactDeputyUser.firstName" autofocus trim />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Last Name:" label-for="last-name">
                    <b-form-input id="last-name" v-model="contactDeputyUser.lastName" autofocus trim />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="6">
                  <b-form-group label="E-mail:" label-for="e-mail">
                    <b-form-input id="e-mail" v-model="contactDeputyUser.email" autofocus trim />

                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Phone:" label-for="phone">
                    <b-form-input id="phone" v-model="contactDeputyUser.phone" autofocus trim />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row class="text-right">
                <b-col md="12">
                  <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" :disabled="!contactDeputyUser.firstName || !contactDeputyUser.lastName || !contactDeputyUser.email ||
                    !contactDeputyUser.phone" @click="addDeputyContact">
                    Save
                  </b-button>
                </b-col>
              </b-row>
            </div>


            <div class="text-center pt-2 pb-2">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-danger" class="mr-2"
                @click="onCancel">
                Cancel
              </b-button>
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit">
                Save
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-modal>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BInputGroup, BInputGroupPrepend, BFormSelect,
  BBadge, BDropdown, BDropdownItem, BPagination, BTooltip, BSidebar, BForm, BFormGroup, BFormInvalidFeedback, BFormCheckbox, BFormTextarea, BModal
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { onUnmounted, ref } from '@vue/composition-api'
import store from '@/store'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import useClientList from './useClientList'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { required, email, url } from '@validations'
import Parse from 'parse/dist/parse.min.js';

import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// import invoiceStoreModule from '../invoiceStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BSidebar, BModal,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BFormCheckbox,
    BFormTextarea,
    BInputGroup,
    BInputGroupPrepend,
    BFormSelect,
    flatPickr,
    vSelect,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      showModal: false,
      // eventLocal: { extendedProps: {} }
      client: {
        showAssistantName: true
      },
      users: [],
      showPrimaryUserForm: false,
      contactPrimaryUser: {},
      showDeputyUserForm: false,
      contactDeputyUser: {},
      contactRoleId: "",
    }
  },
  props: {
    eventLocal: {
      type: Object,
      required: false,
      default: () => ({ extendedProps: {} })
    },
  },
  created() {
    this.loadUsers()
    this.loadRoles()
  },
  setup(props) {
    // const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

    // // Register module
    // if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)

    // // UnRegister on leave
    // onUnmounted(() => {
    //   if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    // })

    const statusOptions = [
      'Downloaded',
      'Draft',
      'Paid',
      'Partial Payment',
      'Past Due',
    ]

    const {
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,

      statusFilter,

      refetchData,

      resolveUserStatusVariantAndIcon,
      resolveClientAvatarVariant,
    } = useClientList()

    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation(null, null)

    const refInputEl = ref(null)
    const previewEl = ref(null)
    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => { previewEl.value.src = base64 })

    return {
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,

      statusFilter,

      refetchData,

      statusOptions,

      avatarText,
      resolveUserStatusVariantAndIcon,
      resolveClientAvatarVariant,

      getValidationState,
      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer
    }
  },
  methods: {
    openUserModal() {
      this.client = { showAssistantName: true }
      this.$refs['modal'].show()
    },
    showNotification(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Notification',
          icon: 'BellIcon',
          text: '👋 Chocolate oat cake jelly oat cake candy jelly beans pastry.',
          variant
        },
      })
    },
    onCloseModal(val) {
      this.showModal = val
    },
    onCancel() {
      this.showModal = false
    },
    onSubmit() {
      const Client = Parse.Object.extend("Client");
      const client = new Client();
      if (this.client.id) {
        client.set("id", this.client.id)
      }
      client.set("name", this.client.name)
      client.set("department", this.client.department)
      client.set("addressStreet", this.client.addressStreet)
      client.set("addressZIP", this.client.addressZIP)
      client.set("addressCity", this.client.addressCity)
      client.set("addressCanton", this.client.addressCanton)
      client.set("rate", parseInt(this.client.rate))
      client.set("vatNumber", this.client.vatNumber)
      client.set("showAssistantName", this.client.showAssistantName)

      if (this.client.primaryContact) {
        const User = Parse.Object.extend("_User");
        const user = new User();
        user.id = this.client.primaryContact
        client.set("primaryContact", user)
      }

      if (this.client.deputyContact) {
        const User = Parse.Object.extend("_User");
        const user = new User();
        user.id = this.client.deputyContact
        client.set("deputyContact", user)
      }

      client.save()
        .then((savedClient) => {
          // Execute any logic that should take place after the object is saved.
          this.$refs['modal'].hide()
          this.refetchData()

          let userIds = [];

          if (this.client.primaryContact) {
            userIds = [...userIds, this.client.primaryContact];
          }
          if (this.client.deputyContact) {
            userIds = [...userIds, this.client.deputyContact];
          }

          const User = Parse.Object.extend("_User");
          const query = new Parse.Query(User);

          query.containedIn("objectId", userIds);
          query.find().then((results) => {
            for (const user of results) {
              user.set("client", client)
              user.save();
            }
          })

        }, (error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error creating a client",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          })
        });
    },
    loadUsers() {
      const roleQuery = new Parse.Query(Parse.Object.extend("_Role"));
      roleQuery.containedIn("name", ["manager", "contact"]);

      roleQuery.find().then(result => {
        const query = new Parse.Query(Parse.Object.extend("_User"));

        const Role = Parse.Object.extend("_Role");
        const role1 = new Role();
        role1.id = result[0].id
        const role2 = new Role();
        role2.id = result[1].id

        query.containedIn("role", [role1, role2]);

        query
          .find()
          .then((userList) => {
            this.users = userList.map(user => ({
              value: user.id,
              text: `${user.attributes.firstName} ${user.attributes.lastName}`
            }));
          })
      })
    },
    editClient(data) {
      this.client = {
        ...data.item.attributes, id: data.item.id,
        ...(data.item.attributes.primaryContact ? { primaryContact: data.item.attributes.primaryContact.id } : {}),
        ...(data.item.attributes.deputyContact ? { deputyContact: data.item.attributes.deputyContact.id } : {})
      }
      this.$refs['modal'].show()
    },
    formatDate(date) {
      return moment(date).format("DD.MM.YYYY")
    },
    async loadRoles() {
      const Role = Parse.Object.extend("_Role");
      const query = new Parse.Query(Role);
      const result = await query
        .find()
      const roles = result.map(res => ({
        value: res.id,
        text: `${res.attributes.displayName}`
      }))
      this.contactRoleId = roles.filter(role => role.text === 'Contact')[0].value
    },
    addPrimaryContact() {
      const User = Parse.Object.extend("User");

      const user = new User();

      user.set("email", this.contactPrimaryUser.email)
      user.set("username", this.contactPrimaryUser.email)
      user.set("password", this.contactPrimaryUser.email)
      user.set("lastName", this.contactPrimaryUser.lastName)
      user.set("firstName", this.contactPrimaryUser.firstName)
      user.set("phone", this.contactPrimaryUser.phone)

      const Role = Parse.Object.extend("_Role");
      const role = new Role();
      role.id = this.contactRoleId
      user.set("role", role)

      const acl = new Parse.ACL(Parse.User.current())
      acl.setPublicReadAccess(true)
      acl.setPublicWriteAccess(true)

      user.setACL(acl)

      user.save().then((savedUser) => {
        this.contactPrimaryUser = {}
        this.loadUsers()
        this.client.primaryContact = savedUser.id
        this.showPrimaryUserForm = false
      }).catch((ex) => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error creating a contact user",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        })
      })
    },
    addDeputyContact() {
      const User = Parse.Object.extend("User");

      const user = new User();

      user.set("email", this.contactDeputyUser.email)
      user.set("username", this.contactDeputyUser.email)
      user.set("password", this.contactDeputyUser.email)
      user.set("lastName", this.contactDeputyUser.lastName)
      user.set("firstName", this.contactDeputyUser.firstName)
      user.set("phone", this.contactDeputyUser.phone)

      const Role = Parse.Object.extend("_Role");
      const role = new Role();
      role.id = this.contactRoleId
      user.set("role", role)

      const acl = new Parse.ACL(Parse.User.current())
      acl.setPublicReadAccess(true)
      acl.setPublicWriteAccess(true)

      user.setACL(acl)

      user.save().then((savedUser) => {
        this.contactDeputyUser = {}
        this.loadUsers()
        this.client.deputyContact = savedUser.id
        this.showDeputyUserForm = false
      }).catch((ex) => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error creating a contact user",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.user-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
