import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import Parse from "parse/dist/parse.min.js";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useClientList() {
  // Use toast
  const toast = useToast();

  const refUserListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "hospital", label: "Hospital", sortable: true },
    { key: "department", label: "Department", sortable: true },
    { key: "address", label: "Address" },
    { key: "contactPerson", label: "Contact Person" },
    { key: "onboardSince", label: "Onboard Since", sortable: true },
    { key: "rate", label: "Rate", sortable: true },
    { key: "actions" },
  ];
  const perPage = ref(10);
  const totalUsers = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const statusFilter = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value
      ? refUserListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    };
  });

  const refetchData = () => {
    refUserListTable.value.refresh();
  };

  watch([currentPage, perPage, searchQuery, statusFilter], () => {
    refetchData();
  });

  const fetchUsers = async (ctx, callback) => {
    const Client = Parse.Object.extend("Client");
    const query = new Parse.Query(Client);

    if (sortBy.value === "hospital" && isSortDirDesc.value) {
      query.descending("name");
    } else {
      query.ascending("name");
    }

    if (sortBy.value === "department" && isSortDirDesc.value) {
      query.descending("department");
    } else {
      query.ascending("department");
    }

    if (sortBy.value === "onboardSince" && isSortDirDesc.value) {
      query.descending("createdAt");
    } else {
      query.ascending("createdAt");
    }

    if (sortBy.value === "rate" && isSortDirDesc.value) {
      query.descending("rate");
    } else {
      query.ascending("rate");
    }
    query.withCount();
    query.skip(perPage.value * (currentPage.value - 1));
    query.limit(perPage.value);
    if (searchQuery.value) {
      query.fullText("name", searchQuery.value);
    }
    query
      .find()
      .then(({ results, count }) => {
        callback(results);
        totalUsers.value = count;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching client list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserStatusVariantAndIcon = (status) => {
    if (status === "Partial Payment")
      return { variant: "warning", icon: "PieChartIcon" };
    if (status === "Paid")
      return { variant: "success", icon: "CheckCircleIcon" };
    if (status === "Downloaded")
      return { variant: "info", icon: "ArrowDownCircleIcon" };
    if (status === "Draft") return { variant: "primary", icon: "SaveIcon" };
    if (status === "Sent") return { variant: "secondary", icon: "SendIcon" };
    if (status === "Past Due") return { variant: "danger", icon: "InfoIcon" };
    return { variant: "secondary", icon: "XIcon" };
  };

  const resolveClientAvatarVariant = (status) => {
    if (status === "Partial Payment") return "primary";
    if (status === "Paid") return "danger";
    if (status === "Downloaded") return "secondary";
    if (status === "Draft") return "warning";
    if (status === "Sent") return "info";
    if (status === "Past Due") return "success";
    return "primary";
  };

  return {
    fetchUsers,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,

    statusFilter,

    resolveUserStatusVariantAndIcon,
    resolveClientAvatarVariant,

    refetchData,
  };
}
